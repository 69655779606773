import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import { graphql } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faUser, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import showdown from 'showdown'

const ContactPage = ({data}) => {
  const converter = new showdown.Converter()
  const {frontmatter} = data.markdownRemark
  const {edges} = data.allMarkdownRemark
  return(
    <Layout>
      <SEO title="Contact" />
      {edges.map(edge => (
        edge.node.frontmatter.templateKey === "home" && 
          <section className="hero is-fullheight is-home" style={{backgroundImage: `url(${edge.node.frontmatter.photoHeader})`}}>
            <div className="hero-header">
                <Header siteTitle={data.site.siteMetadata.title} />
            </div>
            <div className="hero-body">
              <div className="container has-text-centered">
                <h1 className="title is-size-1 has-text-white is-size-2-mobile">
                  Contacter Nathalie BRUMAN
                </h1>
              </div>
            </div>
          </section>
      ))}
      <section className="hero is-primary is-medium">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-10 has-text-white">
                <h1 className="title has-text-white is-size-2-mobile">
                  Prendre un rendez-vous : 
                </h1>
                <div className="columns is-centered">
                  <div className="column is-9">
                    <div className="has-text-centered">
                      <p className="is-size-4">Consultations uniquement sur rendez-vous : </p>
                      <p className="has-text-weight-semibold is-size-4">{frontmatter.tel}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="hero">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-10">
                <h1 className="title">
                  Accéder au cabinet
                </h1>
                <div className="columns is-centered is-vcentered">
                  <div className="column is-5 is-6-tablet">
                    <div className="has-text-centered column-maps-google">
                      <iframe title="google maps" className="maps-google" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d538921.3469495687!2d-0.9310825050574755!3d49.04055503688989!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x480a365e74135c49%3A0xb7f782adeb7b1980!2sLa%20Rouelle%2C%2014260%20Bonnemaison!5e0!3m2!1sfr!2sfr!4v1577007362748!5m2!1sfr!2sfr" width="500" height="700" frameborder="0" style={{border:"0"}} allowfullscreen=""></iframe>
                    </div>
                  </div>
                  <div className="column is-3 is-4-tablet">
                    <div className="has-text-centered">
                      <div className="has-text-centered">
                        <FontAwesomeIcon className="icon-more-informations" icon={faMapMarkerAlt} />
                      </div>
                      <div className="box has-text-centered box-more-information">
                        <br></br>
                        <p className="has-text-weight-semibold is-size-5">Accéder au cabinet</p>
                        <div className="text-more-information">
                          <p>{frontmatter.adresse}</p>
                          <p>{frontmatter.complementAdresse}</p>
                          <p>{frontmatter.codepostal} {frontmatter.ville} </p>
                        </div>
                      </div>
                      <p>Possibilité de me déplacer à votre domicile ou en entreprise  (sous conditions) :</p>
                      <p className="mt-2">Merci de me contacter pour plus de précisions à ce sujet.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="hero is-medium">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-10">
                <h1 className="title">
                  Infos / Tarifs : 
                </h1>
                <div className="columns is-centered">
                  <div className="column is-9 is-10-tablet">
                      {edges.map(item => (
                        item.node.frontmatter.templateKey === "contact" && 
                          <div className="has-text-left content" dangerouslySetInnerHTML={{ __html: converter.makeHtml(item.node.frontmatter.infosTarifs) }} />
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section className="hero is-primary is-medium" style={{borderBottom: "1px solid #FFF"}}>
        <div className="hero-body">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-10">
                <h1 className="title has-text-white">
                  Pour plus de renseignements : 
                </h1>
                <form action="https://usebasin.com/f/9821f49eabc7" method="POST">
                  <div className="columns is-centered mt-2">
                    <div className="column is-10 is-12-tablet">
                      <div className="columns is-centered">
                        <div className="column is-5">
                          <div className="field">
                            <label className="label has-text-white">Nom : 
                              <div className="control has-icons-left">
                                <input className="input" type="text" name="Nom" placeholder="Votre nom"/>
                                <span className="icon is-small is-left">
                                  <FontAwesomeIcon  icon={faUser}/>
                                </span>
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="column is-5">
                          <div className="field">
                            <label className="label has-text-white">Adresse e-mail :
                              <div className="control has-icons-left">
                                <input className="input" type="mail" name="Adresse e-mail" placeholder="Votre adresse e-mail"/>
                                <span className="icon is-small is-left">
                                  <FontAwesomeIcon  icon={faEnvelope}/>
                                </span>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="columns is-centered">
                        <div className="column is-10">
                          <div className="field">
                            <label className="label has-text-white">Message :
                              <div className="control">
                                <textarea className="textarea" rows="8" name="Message" placeholder="Votre messsage ..."></textarea>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="control has-text-centered">
                        <button className="button is-rounded">Envoyer</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )

}

ContactPage.defaultProps = {
  tel: ``,
}

export default ContactPage


export const ContactPageQuery = graphql`
query ContactPage {
  site {
    siteMetadata {
      title
    }
  }
  markdownRemark(frontmatter: {templateKey: {eq: "info"}}) {
    frontmatter {
      tel
      adresse
      ville
      codepostal
      complementAdresse
    }
  }
  allMarkdownRemark {
    edges {
      node {
        frontmatter {
          templateKey
          infosTarifs
          photoHeader
        }
      }
    }
  }
}
`